import * as echarts from 'echarts';

export class CharsDataMapper{
    constructor(data) {
        // 数据项
        this.series = [
            {
                name: '应巡检数',
                data: [],
            },
            {
                name: '实巡检数',
                data: [],
            },
        ]

        // x轴标签
        this.xLabel = []
        // console.log(data, '------data-');
        data.forEach(iter => {
            this.xLabel.push(iter.buildName)
            this.series[0].data.push(iter.should)
            this.series[1].data.push(iter.reality)
        })
    }

    get(){
        // topLabel, xLabel, series
        return {
            topLabel: ['应巡检数', '实巡检数'],
            xLabel: this.xLabel,
            series: this.series,
        }
    }
}

export class CharsDataMapper2{
    constructor(data){
        // 数据项
        this.series = [
            {
                name: '预警',
                barWidth: '40',
                data: [],
            },
        ]

        // x轴标签
        this.xLabel = []
        Array.isArray(data) && data.forEach(iter => {
            this.xLabel.push(iter.name)
            this.series[0].data.push(iter.number)
        })
    }
    get(){
        // topLabel, xLabel, series
        return {
            topLabel: ['预警'],
            xLabel: this.xLabel,
            series: this.series,
        }
    }
}

export class DailyInspectionChars{
    constructor(did) {
        this.app = {}
        this.did = did
        this.myChart = null
        this.draw()
        this.initConfig()
    }

    initConfig(){
        const posList = [
            'left',
            'right',
            'top',
            'bottom',
            'inside',
            'insideTop',
            'insideLeft',
            'insideRight',
            'insideBottom',
            'insideTopLeft',
            'insideTopRight',
            'insideBottomLeft',
            'insideBottomRight'
        ]

        this.app.configParameters = {
            rotate: {
                min: -90,
                max: 90
            },
            align: {
                options: {
                    left: 'left',
                    center: 'center',
                    right: 'right'
                }
            },
            verticalAlign: {
                options: {
                    top: 'top',
                    middle: 'middle',
                    bottom: 'bottom'
                }
            },
            position: {
                options: posList.reduce(function (map, pos) {
                    map[pos] = pos;
                    return map;
                }, {})
            },
            distance: {
                min: 0,
                max: 100
            }
        }

        this.app.config = {
            rotate: 0,
            align: 'center',
            verticalAlign: 'middle',
            position: 'top',
            distance: 20,
            onChange: function () {
                const labelOption = {
                    rotate: this.app.config.rotate,
                    align: this.app.config.align,
                    verticalAlign: this.app.config.verticalAlign,
                    position: this.app.config.position,
                    distance: this.app.config.distance
                }
                this.myChart.setOption({
                    series: [
                        {
                            label: labelOption
                        },
                        {
                            label: labelOption
                        },
                        {
                            label: labelOption
                        },
                        {
                            label: labelOption
                        }
                    ]
                })
            }
        }

        const { app } = this

        this.labelOption = {
            show: true,
            position: app.config.position,
            distance: app.config.distance,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            rotate: app.config.rotate,
            // formatter: '{c}  {name|{a}}',
            fontSize: 14,
            rich: {
                name: {}
            }
        }

        this.option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            // 标签
            legend: {
                data: ['Forest', 'Steppe', 'Desert', 'Wetland']
            },
            color: ['#4472c4', '#ed7d31', '#ed7d31', '#5470c6'],
            toolbox: {
                show: false,
                orient: 'vertical',
                left: 'right',
                top: 'center',
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar', 'stack'] },
                    restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: { show: false },
                    data: ['2012', '2013', '2014', '2015', '2016']
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                // {
                //     name: '应巡检数',
                //     type: 'bar',
                //     barGap: 0,
                //     label: labelOption,
                //     emphasis: {
                //         focus: 'series'
                //     },
                //     data: [320, 332, 301, 334, 390]
                // },
                // {
                //     name: '实巡检数',
                //     type: 'bar',
                //     label: labelOption,
                //     emphasis: {
                //         focus: 'series'
                //     },
                //     data: [220, 182, 191, 234, 290]
                // },

                // {
                //     name: 'Desert',
                //     type: 'bar',
                //     label: labelOption,
                //     emphasis: {
                //         focus: 'series'
                //     },
                //     data: [150, 232, 201, 154, 190]
                // },
                // {
                //     name: 'Wetland',
                //     type: 'bar',
                //     label: labelOption,
                //     emphasis: {
                //         focus: 'series'
                //     },
                //     data: [98, 77, 101, 99, 40]
                // }
            ]
        }

        this.updateChars()
    }

    updateData({ topLabel, xLabel, series }){
        // 顶部标签 ['应巡检数', '实巡检数']
        this.option.legend.data = topLabel

        // x轴标签 ['2012', '2013', '2014', '2015', '2016']
        this.option.xAxis[0].data = xLabel

        // 数据项
        const tSeries = series
        tSeries.map(iter => {
            iter.type = 'bar'
            iter.label = this.labelOption
            iter.emphasis = { focus: 'series' }
        })
        this.option.series = tSeries
        this.updateChars()
    }

    updateChars(){
        if (this.option && this.myChart) {
            this.myChart.setOption(this.option)
        }
    }

    draw(){
        const chartDom = document.getElementById(this.did)
        this.myChart = echarts.init(chartDom)
    }
}

export class DailyInspectionCharsLine{
    constructor(did) {
        this.did = did
        this.myChart = null
        this.draw()
        this.initConfig()
    }

    initConfig(){
        this.option = {
            legend: {
                data: ['预警点及数量'],
            },
            tooltip: {
                show: true,
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: [],
            },
            yAxis: {
                type: 'value',
                alignTicks: true,
            },
            series: [
                {
                    data: [],
                    type: 'line',
                    name: '预警点及数量',
                }
            ]
        }

        this.updateChars()
    }

    updateData({ xData, seriesData }){
        this.option.xAxis.data = xData
        this.option.series[0].data = seriesData
        this.updateChars()
    }

    updateChars(){
        if (this.option && this.myChart) {
            this.myChart.setOption(this.option)
        }
    }

    draw(){
        const chartDom = document.getElementById(this.did)
        this.myChart = echarts.init(chartDom)
    }
}

export class DailyInspectionCharsBiscuit{
    constructor(did) {
        this.did = did
        this.myChart = null
        this.draw()
        this.initConfig()
    }

    initConfig(){
        this.option = {
            title: {
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [
                {
                    name: '处理类型',
                    type: 'pie',
                    radius: '50%',
                    data: [],
                    label: {
                        show: true,
                        formatter: '{d}%',
                        position: 'inside',
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                }
            ]
        }

        this.updateChars()
    }

    updateData({ seriesData }){
        this.option.series[0].data = seriesData
        this.updateChars()
    }

    updateChars(){
        if (this.option && this.myChart) {
            this.myChart.setOption(this.option)
        }
    }

    draw(){
        const chartDom = document.getElementById(this.did)
        this.myChart = echarts.init(chartDom)
    }
}
