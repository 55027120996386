<script>
import {getXjDataViewDay, getXjDataViewFire, getXjDataViewHydrant, getXjDataViewWeekEquipment} from "@/api/examination";
import {CharsDataMapper, DailyInspectionChars} from "@/utils/inspectionBoard";
import dayjs from "dayjs";
import {integerParse} from "@/utils/common";

export default {
  name: "statistics",
  data(){
    const defaultDate = dayjs().format('YYYY-MM')

    return {
      params1: { date: defaultDate, should: '', },
      params2: { date: defaultDate, should: '', },
      params3: { date: defaultDate, should: '', },
      params4: { date: defaultDate, week: '1', },
    }
  },
  mounted(){
    this.updateXjDataViewDay()
    this.updateXjDataViewFire()
    this.updateXjDataViewHydrant()
    this.updateXjDataViewWeekEquipment()
  },
  methods: {
    updateXjDataViewDay(){
      integerParse(this.params1.should, val => this.params1.should = val)
      getXjDataViewDay(this.params1).then(res => {
        if (res.success) {
          //
          this.$nextTick(() => {
            const dailyInspectionChars = new DailyInspectionChars('DailyInspection')
            const charsDataMapper = new CharsDataMapper(res.data.data)
            dailyInspectionChars.updateData(charsDataMapper.get())
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    updateXjDataViewFire(){
      integerParse(this.params2.should, val => this.params2.should = val)
      getXjDataViewFire(this.params2).then(res => {
        if (res.success) {
          //
          this.$nextTick(() => {
            const dailyInspectionChars = new DailyInspectionChars('DailyInspection2')
            const charsDataMapper = new CharsDataMapper(res.data.data)
            dailyInspectionChars.updateData(charsDataMapper.get())
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    updateXjDataViewHydrant(){
      integerParse(this.params3.should, val => this.params3.should = val)
      getXjDataViewHydrant(this.params3).then(res => {
        if (res.success) {
          //
          this.$nextTick(() => {
            const dailyInspectionChars = new DailyInspectionChars('DailyInspection3')
            const charsDataMapper = new CharsDataMapper(res.data.data)
            dailyInspectionChars.updateData(charsDataMapper.get())
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    updateXjDataViewWeekEquipment(){
      integerParse(this.params4.week, val => this.params4.week = val)
      getXjDataViewWeekEquipment(this.params4).then(res => {
        if (res.success) {
          //
          this.$nextTick(() => {
            const dailyInspectionChars = new DailyInspectionChars('DailyInspection4')
            const charsDataMapper = new CharsDataMapper(res.data.data)
            dailyInspectionChars.updateData(charsDataMapper.get())
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="chars-container">
          <div class="filter-row">
            <div>每日巡检看板</div>
            <div class="filter-item-box">
              <el-date-picker v-model="params1.date" value-format="YYYY-MM" type="month" placeholder="选择月"></el-date-picker>
              <el-input style="width: 120px" class="marginLeft10" v-model="params1.should" placeholder="应巡检数" />
              <el-button class="marginLeft10" type="primary" @click="updateXjDataViewDay">确认</el-button>
            </div>
          </div>
          <div class="chars-container-item" id="DailyInspection">

          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="chars-container">
          <div class="filter-row">
            <div>灭火器巡检看板</div>
            <div class="filter-item-box">
              <el-date-picker v-model="params2.date" value-format="YYYY-MM" type="month" placeholder="选择月"></el-date-picker>
              <el-input style="width: 120px" class="marginLeft10" v-model="params2.should" placeholder="应巡检数" />
              <el-button class="marginLeft10" type="primary" @click="updateXjDataViewFire">确认</el-button>
            </div>
          </div>
          <div class="chars-container-item" id="DailyInspection2">

          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="marginTop20px">
      <el-col :span="12">
        <div class="chars-container">
          <div class="filter-row">
            <div>消火栓巡检看板</div>
            <div class="filter-item-box">
              <el-date-picker v-model="params3.date" value-format="YYYY-MM" type="month" placeholder="选择月"></el-date-picker>
              <el-input style="width: 120px" class="marginLeft10" v-model="params3.should" placeholder="应巡检数" />
              <el-button class="marginLeft10" type="primary" @click="updateXjDataViewHydrant">确认</el-button>
            </div>
          </div>
          <div class="chars-container-item" id="DailyInspection3">

          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="chars-container">
          <div class="filter-row">
            <div>周设备巡检看板</div>
            <div class="filter-item-box">
              <el-date-picker v-model="params4.date" value-format="YYYY-MM" type="month" placeholder="选择月"></el-date-picker>
              <el-input style="width: 120px" class="marginLeft10" v-model="params4.week" placeholder="周次" />
              <el-button class="marginLeft10" type="primary" @click="updateXjDataViewWeekEquipment">确认</el-button>
            </div>
          </div>
          <div class="chars-container-item" id="DailyInspection4">

          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
  .chars-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 488px;
    background-color: #fff;
    border-radius: 8px;
    text-align: left;
    box-sizing: border-box;

    .filter-row{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      .filter-item-box{
        display: flex;
      }
    }
    .chars-container-item{
      flex: 1;
    }
  }
</style>